"use client";

import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import Fab from "@mui/material/Fab";
import {
  Box,
  Grow,
  Stack,
  ClickAwayListener,
  useMediaQuery,
  alpha,
  useTheme,
} from "@mui/material";
import Chat from "./Chat";
import { ChatContext } from "./context";
import { useWidgetSettingsContext } from "./settings";
import chroma from "chroma-js";
import Iconify from "./components/iconify";
import Image from "./components/image";
import { ReturnType, useBoolean } from "./hooks/use-boolean";
import useNewSessionStorage from "./hooks/use-new-session-storage";

interface WidgetChatProps {
  getChatOpenState?: ReturnType;
}

export default function WidgetChat({
  getChatOpenState,
}: WidgetChatProps): React.ReactNode | null {
  const { chatOpen, setChatOpen, editMode, isHome } = useContext(ChatContext);
  const [allowedWidget, setAllowedWidget] = useState<boolean>(true);
  const isMobile = useMediaQuery("(max-width: 600px)");
  const elementRef = useRef(null);
  const theme = useTheme();
  const settings = useWidgetSettingsContext();
  const [sessionAutoOpenWidget, setSessionAutoOpenWidget] =
    useNewSessionStorage<{ widgetId: string; isShown: boolean }>(
      "widget-auto-open",
      { widgetId: settings.widgetId || "1", isShown: false }
    );

  const isAnimating = useBoolean();
  const zIndex = chatOpen || isAnimating.value ? 1200 : -1;

  const fabBoxStyle = useMemo(
    () => ({
      position: "fixed",
      zIndex: 999,
      ...(settings.position === "left"
        ? {
            left: settings.sideSpacing,
          }
        : {
            right: settings.sideSpacing,
          }),

      bottom: settings.bottomSpacing,
      "& .MuiFab-root": {
        backgroundColor: settings.laucherBgColor!,
        " &:hover, &:active": {
          backgroundColor: alpha(settings.laucherBgColor!, 0.8),
        },
      },
    }),
    [theme, settings]
  );

  const chatBoxStyle = useMemo(
    () => ({
      position: "fixed",
      bottom: {
        xs: 0,
        sm: 76 + settings.bottomSpacing,
        md: 86 + settings.bottomSpacing,
      },

      ...(settings.position === "left"
        ? {
            left: {
              xs: 0,
              sm: settings.sideSpacing,
            },
          }
        : {
            right: {
              xs: 0,
              sm: settings.sideSpacing,
            },
          }),

      minWidth: {
        sm: 300,
      },
      maxWidth: 600,
      width: {
        xs: 1,
        sm: 0.4,
        md: 0.3,
      },
    }),
    [settings]
  );

  const handleClickAway = () => {
    if (!editMode && !isHome) setChatOpen(false);
  };

  useEffect(() => {
    if (editMode) return;
    if (
      settings.allowAutoOpenTimer &&
      settings.autoOpenTimer &&
      sessionAutoOpenWidget.widgetId === settings.widgetId &&
      !sessionAutoOpenWidget.isShown
    ) {
      const timer = setTimeout(() => {
        setChatOpen(true);
        setSessionAutoOpenWidget({
          widgetId: settings.widgetId as string,
          isShown: true,
        });
      }, settings.autoOpenTimer * 1000);

      return () => clearTimeout(timer);
    }
  }, [
    settings.allowAutoOpenTimer,
    settings.autoOpenTimer,
    settings.widgetId,
    editMode,
    sessionAutoOpenWidget,
  ]);

  useEffect(() => {
    if (!getChatOpenState) return;
    if (chatOpen) getChatOpenState.onTrue();
    else getChatOpenState.onFalse();
  }, [chatOpen]);

  useEffect(() => {
    // When the widget is opened
    if (chatOpen) {
      window.parent.postMessage(
        { widgetState: "open", height: "700px", width: "350px" },
        "*"
      );
      if (window.innerWidth < 600) {
        document.documentElement.style.cssText = "overflow: hidden !important";
      }
    } else {
      window.parent.postMessage(
        { widgetState: "closed", height: "56px", width: "56px" },
        "*"
      );
      document.documentElement.style.cssText = "overflow: auto !important";
    }

    // When the widget is closed
  }, [chatOpen]);

  useEffect(() => {
    if (editMode) return;
    if (settings?.allowDomains && settings?.domainList?.length) {
      if (typeof window !== "undefined") {
        const currentDomain = window.location.hostname?.replace("www.", "");
        const isDomainAllowed = settings.domainList.some(
          (domain) => domain === currentDomain
        );
        setAllowedWidget(!!isDomainAllowed);
      }
    }
  }, [settings?.allowDomains, settings?.domainList, editMode]);

  if (!allowedWidget) return null;

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <Box>
        <Stack id="chat-widget-container" sx={{ zIndex, ...chatBoxStyle }}>
          <Grow
            in={chatOpen}
            style={{
              transformOrigin: "bottom right",
            }}
            onEnter={() => isAnimating.onTrue()}
            onExited={() => isAnimating.onFalse()}
          >
            <Box sx={{ width: 1 }}>
              <Chat />
            </Box>
          </Grow>
        </Stack>
        <Box id="chat-widget-fab" sx={fabBoxStyle} ref={elementRef}>
          {isMobile && !chatOpen && (
            <Grow in style={{ transformOrigin: "top left" }}>
              <Fab
                onClick={() => {
                  setChatOpen((prev) => !prev);
                }}
                sx={{
                  p: 1,
                }}
              >
                {chatOpen ? (
                  <Iconify
                    icon={"material-symbols:close-rounded"}
                    sx={{
                      color:
                        chroma(settings.laucherBgColor || "white").luminance() >
                        0.5
                          ? "#000"
                          : "white",
                    }}
                  />
                ) : (settings?.launcherIcon as any)?.preview ? (
                  <Image
                    sx={{
                      borderRadius: "100%",
                      aspectRatio: 1,
                      width: 1,
                    }}
                    src={(settings?.launcherIcon as any)?.preview}
                  />
                ) : (
                  <Iconify
                    icon={"ic:round-chat"}
                    sx={{
                      color:
                        chroma(settings.laucherBgColor || "white").luminance() >
                        0.5
                          ? "#000"
                          : "#fff",
                    }}
                  />
                )}
              </Fab>
            </Grow>
          )}

          {!isMobile && (
            <Fab
              onClick={() => {
                setChatOpen((prev) => !prev);
              }}
              sx={{
                p: 1,
              }}
            >
              {chatOpen ? (
                <Iconify
                  icon={"material-symbols:close-rounded"}
                  sx={{
                    color:
                      chroma(settings.laucherBgColor || "white").luminance() >
                      0.5
                        ? "#000"
                        : "#fff",
                  }}
                />
              ) : (settings?.launcherIcon as any)?.preview ? (
                <Image
                  sx={{
                    borderRadius: "100%",
                    aspectRatio: 1,
                    width: 1,
                  }}
                  src={(settings?.launcherIcon as any)?.preview}
                />
              ) : (
                <Iconify
                  icon={"ic:round-chat"}
                  sx={{
                    color:
                      chroma(settings.laucherBgColor || "white").luminance() >
                      0.5
                        ? "#000"
                        : "#fff",
                  }}
                />
              )}
            </Fab>
          )}
        </Box>
      </Box>
    </ClickAwayListener>
  );
}
