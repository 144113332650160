import "./code-highlight-block.css";

import type { Options } from "react-markdown";

import React, { memo } from "react";
import remarkGfm from "remark-gfm";
import rehypeRaw from "rehype-raw";

import Link from "@mui/material/Link";

import { StyledRoot } from "./styles";
import { markdownClasses } from "./classes";

import type { MarkdownProps } from "./types";
import MarkdownImage from "../markdown-image";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import LanguageCodeblock from "./components/language-codeblock";

// ----------------------------------------------------------------------
export function isExternalLink(url: string): boolean {
  return url.startsWith("http");
}

function Markdown({ children, sx, ...other }: MarkdownProps) {
  return (
    <StyledRoot
      children={children}
      components={components as Options["components"]}
      rehypePlugins={rehypePlugins as Options["rehypePlugins"]}
      /* base64-encoded images
       * https://github.com/remarkjs/react-markdown/issues/774
       * urlTransform={(value: string) => value}
       */
      className={markdownClasses.root}
      sx={sx}
      {...other}
    />
  );
}

// ----------------------------------------------------------------------

export type ComponentTag = {
  [key: string]: any;
};

const rehypePlugins = [rehypeRaw, [remarkGfm, { singleTilde: false }]];

const components = {
  img: ({ ...other }: ComponentTag) => (
    <MarkdownImage
      ratio="16/9"
      className={markdownClasses.content.image}
      sx={{ borderRadius: 2 }}
      {...other}
    />
  ),
  a: ({ href, children, ...other }: ComponentTag) => {
    const linkProps = isExternalLink(href)
      ? { target: "_blank", rel: "noopener" }
      : {};

    return (
      <Link
        {...linkProps}
        href={href}
        className={markdownClasses.content.link}
        {...other}
        sx={{
          color: "primary.dark",
        }}
      >
        {children}
      </Link>
    );
  },
  pre: ({ className, children, ...other }: ComponentTag) => {
    const languageMatch: any = React.Children.toArray(children).find(
      (child: any) =>
        child.props &&
        child.props.className &&
        /language-(\w+)/.test(child.props.className)
    );

    const language = languageMatch
      ? languageMatch.props.className.match(/language-(\w+)/)?.[1]
      : null;

    const code = React.Children.map(
      children,
      (child: any) => child.props?.children
    ).join("");

    return (
      <div className={markdownClasses.content.codeBlock}>
        {language && (
          <LanguageCodeblock
            className={className}
            children={children}
            language={language}
            {...other}
          />
        )}
        <pre
          style={{
            ...(language && {
              marginTop: 0,
              paddingTop: 0,
            }),
          }}
        >
          <SyntaxHighlighter
            language={(language as unknown as string) || "text"}
            style={{}}
            useInlineStyles={false}
            PreTag="div"
            {...other}
          >
            {code}
          </SyntaxHighlighter>
        </pre>
      </div>
    );
  },
  code({ className, children, ...other }: ComponentTag) {
    const language = /language-(\w+)/.exec(className || "");

    return language ? (
      <div>
        <code {...other} className={className}>
          {children}
        </code>
      </div>
    ) : (
      <code {...other} className={markdownClasses.content.codeInline}>
        {children}
      </code>
    );
  },
};

export default memo(Markdown);
